import React from 'react'
import { Helmet } from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
const orgSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://verinite.com/',
  logo:
    'https://admin.verinite.com/wp-content/uploads/2022/12/verinite-open-graph-logo.jpeg',
}
const seoHeader = ({ seoData, alternative, linkhref }) => {
  return (
    <>
      {seoData ? (
        <Helmet>
          {seoData.title ? (
            <title>{ReactHtmlParser(seoData.title)}</title>
          ) : (
            <title>{ReactHtmlParser(alternative)}</title>
          )}
          {seoData.description && (
            <meta
              name="description"
              content={ReactHtmlParser(seoData.description)}
            />
          )}
          {seoData.keywords && (
            <meta name="keywords" content={ReactHtmlParser(seoData.keywords)} />
          )}
          {seoData.schema && (
            <script type="application/ld+json">
              {JSON.stringify(seoData.schema)}
            </script>
          )}
        </Helmet>
      ) : (
        <Helmet>
          <title>{ReactHtmlParser(alternative)}</title>
        </Helmet>
      )}
      <Helmet encodeSpecialCharacters={true}>
        <script type="application/ld+json">{JSON.stringify(orgSchema)}</script>
        <html lang="en" />
        {linkhref && <link rel="alternate" href={linkhref} hrefLang="en" />}
      </Helmet>
    </>
  )
}

export default seoHeader
